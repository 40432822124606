import { setupI18n } from '@/plugins/i18n'
import { appConfig } from '@/configs/configuration'
import th from './locales/th.json'
import en from './locales/en.json'
import mm from './locales/mm.json'

export default setupI18n({
  default: appConfig.i18n.locale || 'th',
  messages: { th, en, mm },
})
