<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? content : 'สมัครสมาชิกมายโลตัส'
    }}</template>
  </metainfo>
  <Modal />
  <!-- <CameraModal
    v-if="cameraModalStore.isOpen"
    v-bind:callback="cameraModalStore.options?.callback"
  /> -->
  <router-view />
</template>
<script lang="ts" setup>
import { computed, onBeforeMount } from 'vue'
import { useMeta } from 'vue-meta'
import { appConfig } from '@/configs/configuration'
import { useRegisterStore } from '@/stores/register.store'
// import { useCameraModalStore } from '@/stores/camera-modal.store'
import { useI18n, LangKey } from '@/plugins/i18n'
import { getParam } from '@/utils/web-location'
import { initLiff } from '@/utils/liff'
import Modal from '@/components/common/modal/Modal.vue'
// import CameraModal from '@/components/common/modal/CameraModal.vue'

const { locale, t } = useI18n()
const registerStore = useRegisterStore()
// const cameraModalStore = useCameraModalStore()

const defaultFromReload = () => {
  if (window.location.pathname !== '/') {
    return
  }
  registerStore.isThaiAccount = true
  registerStore.enableSwitch = true
}

const handleChannal = async () => {
  const channel = getParam('channel')
  const liffClientId = getParam('liffClientId')
  if (channel === 'line' || liffClientId) {
    try {
      const lineInfo = await initLiff(appConfig.liffChannelId!)
      if (lineInfo) {
        registerStore.lineIdToken = lineInfo.lineIdToken
        registerStore.lineProfile = lineInfo.lineProfile
        registerStore.enableSwitch = false
      }
    } catch (error) {
      console.error('initLiff error:', error)
    }
  }
}

useMeta(
  computed(() => ({
    title: t('title'),
    htmlAttrs: { lang: locale.value },
  })),
)

onBeforeMount(() => {
  defaultFromReload()

  const language = getParam('lang') as LangKey | undefined
  locale.value = language || 'th'
  registerStore.eventCode = getParam('event_code') || undefined

  handleChannal()
})
</script>
